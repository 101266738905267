import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    set_clip_id_state, set_direction_state, set_exit_velo_state,
    set_kZoneBox_state, set_launch_angle_state, set_球速_state,
    set_進壘點_x_state, set_進壘點_y_state
} from '../slices/baseballApp';

export default function useFetchKarmaZoneData() {

    const dispatch = useAppDispatch();
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    function handleFetchKarmaZoneData() {

        const karmaZoneField = configs.karmaZoneHost

        fetch('https://karmazone-4a7ed-default-rtdb.asia-southeast1.firebasedatabase.app/.json')
            .then(function (response) {
                return response.json();
            })
            .then(function (allKarmaZoneData) {
                // console.log(allKarmaZoneData[karmaZoneField]);
                dispatch(set_進壘點_x_state(allKarmaZoneData[karmaZoneField]['KZone_Y']))
                dispatch(set_進壘點_y_state(allKarmaZoneData[karmaZoneField]['KZone_Z']))
                dispatch(set_clip_id_state(allKarmaZoneData[karmaZoneField]['Clip_Id']))
                dispatch(set_direction_state(allKarmaZoneData[karmaZoneField]['E_Direct']))
                dispatch(set_launch_angle_state(allKarmaZoneData[karmaZoneField]['E_Angle']))
                dispatch(set_exit_velo_state(allKarmaZoneData[karmaZoneField]['E_Velo']))
                dispatch(set_球速_state(allKarmaZoneData[karmaZoneField]['Velo-rel']))

                // '------ 進壘點座標轉換 START ------'
                let INCH_TO_PIX_y = 225 / 48
                let INCH_TO_PIX_z = 280 / 60

                // 好球帶左44, 右 101, 上 56, 下 125. 本壘板寬17英吋,好球
                //INCH_TO_PIX_y = (70 - 160) / 17
                //INCH_TO_PIX_z = (190 - 85) / 19.7

                let y = parseInt(allKarmaZoneData[karmaZoneField]['KZone_Y']) // 原始KZone_Y
                let z = parseInt(allKarmaZoneData[karmaZoneField]['KZone_Z']) // 原始KZone_Z

                if (z === -100) {
                    z = 0
                }

                let orig_y_at_new = 225 / 2
                let new_y = orig_y_at_new - INCH_TO_PIX_y * y // KarmaZone 左右的座標 Y, 往左打(一壘)是正, 往右打(三壘)是負

                let orig_z_at_new = 280 // Height of the Kzone graph
                let new_z = orig_z_at_new - z * INCH_TO_PIX_z

                let max_y = 225
                let max_z = 280

                if (new_y < 0) {
                    new_y = 0
                }

                if (new_y > max_y) {
                    new_y = max_y
                }

                if (new_z < 0) {
                    new_z = 0
                }

                if (new_z > max_z) {
                    new_z = max_z
                }

                // '------ 進壘點座標轉換 END ------'
                dispatch(set_kZoneBox_state({ x: new_y, y: new_z }))
            });
    }

    return handleFetchKarmaZoneData;

}
