import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_接失誤_state } from '../slices/baseballApp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const CatchErrState = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let catchErrState = records.接失誤;

    let handleChange = (event, newValue) => {
        dispatch(set_接失誤_state(newValue))
    }

    return (
        <ToggleButtonGroup color="primary" value={catchErrState} onChange={handleChange} size="small" exclusive>
            <ToggleButton value="P">1</ToggleButton>
            <ToggleButton value="C">2</ToggleButton>
            <ToggleButton value="1B">3</ToggleButton>
            <ToggleButton value="2B">4</ToggleButton>
            <ToggleButton value="SS">5</ToggleButton>
            <ToggleButton value="3B">6</ToggleButton>
            <ToggleButton value="LF">7</ToggleButton>
            <ToggleButton value="CF">8</ToggleButton>
            <ToggleButton value="RF">9</ToggleButton>
        </ToggleButtonGroup>
    );
}

export default CatchErrState;
