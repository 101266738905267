import { useAppDispatch } from '../app/hooks';
import { set_mark_in_state } from '../slices/baseballApp';

import Button from '@mui/material/Button';

const MarkIn = () => {
    
    let dispatch = useAppDispatch()
    let markInHandleClick = () => {
        const current = new Date();
        const time = `${current.getTime()}`;
        dispatch(set_mark_in_state(time))
    }

    return (
        <Button onClick={markInHandleClick} size="large" fullWidth variant="outlined" color="primary">mark in</Button>
    );
}

export default MarkIn;
