import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_傳失誤_state } from '../slices/baseballApp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PassErrState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const passErrState = records.傳失誤;

    const handleChange = (event, newValue) => {
        dispatch(set_傳失誤_state(newValue))
    }

    return (
        <ToggleButtonGroup color="primary" value={passErrState} onChange={handleChange} size="small" exclusive>
            <ToggleButton value="P">1</ToggleButton>
            <ToggleButton value="C">2</ToggleButton>
            <ToggleButton value="1B">3</ToggleButton>
            <ToggleButton value="2B">4</ToggleButton>
            <ToggleButton value="SS">5</ToggleButton>
            <ToggleButton value="3B">6</ToggleButton>
            <ToggleButton value="LF">7</ToggleButton>
            <ToggleButton value="CF">8</ToggleButton>
            <ToggleButton value="RF">9</ToggleButton>
        </ToggleButtonGroup>
    );
}

export default PassErrState;
