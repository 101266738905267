import { useAppSelector, useAppDispatch } from '../app/hooks';
import useRecordFunc from '../hooks/useRecordFunc';

export default function useJudgeGameState() {

    const records = useAppSelector(state => state.baseballAppReducer.records);
    const configs = useAppSelector(state => state.baseballAppReducer.config);
    const {
        handleAddStrikeState, // 好球 +1
        handleSubStrikeState, // 好球 -1
        handleAddBallState, // 壞球 +1
        handleSubBallState, // 壞球 -1
        handleAddOut, // 出局 +1
        handleSubOut, // 出局 -1
        handleAddBatterOrder, // 打序 +1
        handleSubBatterOrder, // 打序 -1
        handleAddScore, // 分數 +1
        handleSubScore, // 分數 -1
        handleAddInning, // 局數 +1
        handleSubInning, // 局數 -1
        hadleCleanSB, // 清好壞球數
        handleCleanOut // 清出局數
    } = useRecordFunc();
    const dispatch = useAppDispatch();

    function handleJudgeGameState() {

        if (records.結果 === '接殺' || records.結果 === '刺殺' || records.結果 === '美技' || records.結果 === '平飛被接' || records.結果 === '內飛必死') {
            hadleCleanSB()
            handleAddBatterOrder()
            handleAddOut()
        }

        if (records.結果 === '雙殺') {
            hadleCleanSB()
            handleAddBatterOrder()
            handleAddOut()
        }

        if (records.結果 === '三殺') {
            hadleCleanSB()
            handleAddBatterOrder()
            // 壘包上人數>=2或滿壘，且無人出局的狀態下，才會發生三殺打
        }

        if (records.結果 === '觸擊失敗') {
            hadleCleanSB()
            handleAddBatterOrder()
            handleAddOut()
            // 待補，2好球後才會發生
        }

        if (records.結果 === '一壘安' || records.結果 === '二壘安' || records.結果 === '三壘安' || records.結果 === '全壘打') {
            hadleCleanSB()
            handleAddBatterOrder()
        }

        if (records.結果 === 'Bunt' || records.結果 === '飛犧') {
            hadleCleanSB()
            handleAddBatterOrder()
            handleAddOut()
        }

        if (records.結果 === '推進') {
            hadleCleanSB()
            handleAddBatterOrder()
            handleAddOut()
        }

        if (records.結果 === '牽制出局' || records.結果 === '盜二出局' || records.結果 === '盜三出局' || records.結果 === '夾殺出局' || records.結果 === '跑壘失誤') {
            handleAddOut()
        }

    }

    return handleJudgeGameState;

}
