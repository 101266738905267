import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_一接_state } from '../slices/baseballApp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const OnceCatchState = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let onceCatchState = records.一接;

    let handleChange = (event, newValue) => {
        dispatch(set_一接_state(newValue))
    }

    return (
        <ToggleButtonGroup color="primary" value={onceCatchState} onChange={handleChange} size="small" exclusive>
            <ToggleButton value="P">1</ToggleButton>
            <ToggleButton value="C">2</ToggleButton>
            <ToggleButton value="1B">3</ToggleButton>
            <ToggleButton value="2B">4</ToggleButton>
            <ToggleButton value="SS">5</ToggleButton>
            <ToggleButton value="3B">6</ToggleButton>
            <ToggleButton value="LF">7</ToggleButton>
            <ToggleButton value="CF">8</ToggleButton>
            <ToggleButton value="RF">9</ToggleButton>
        </ToggleButtonGroup>
    );
}

export default OnceCatchState;
