import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const LuckyState = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let luckyState = records.結果;
    let style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={luckyState} onChange={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="野選" control={<Radio size="small" color="primary" />} label="野選" />
                <FormControlLabel style={style} value="失誤" control={<Radio size="small" color="primary" />} label="失誤" />
                <FormControlLabel style={style} value="觸身" control={<Radio size="small" color="primary" />} label="觸身" />
                <FormControlLabel style={style} value="敬遠" control={<Radio size="small" color="primary" />} label="敬遠" />
                <FormControlLabel style={style} value="不死三振" control={<Radio size="small" color="primary" />} label="不死三振" />
                <FormControlLabel style={style} value="妨礙打擊" control={<Radio size="small" color="primary" />} label="妨礙打擊" />
            </RadioGroup>
        </FormControl>
    );
}

export default LuckyState;
