import { useAppSelector, useAppDispatch } from '../app/hooks';

import useJudgeGameState from '../hooks/useJudgeGameState';
import useRecordFunc from '../hooks/useRecordFunc';

import Button from '@mui/material/Button';

import {
    check_last_pitch_game_state, clean_fieldZoneBox_state, clean_kZoneBox_state,
    map_config_to_record, set_default_record_state, set_出局者_state, store_record_to_airtable
} from '../slices/baseballApp';

const StoreBtn = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const handleJudgeGameState = useJudgeGameState();
    const { handleAddStrikeState, handleAddBallState } = useRecordFunc();

    const setDefaultState = () => {
        dispatch(clean_kZoneBox_state());
        dispatch(clean_fieldZoneBox_state());
        dispatch(set_default_record_state());
    }

    const handleClick = () => {
        if (records.好壞球 === '好球') {
            handleAddStrikeState()
        }
        if (records.好壞球 === '壞球') {
            handleAddBallState()
        }
        dispatch(map_config_to_record());
        dispatch(store_record_to_airtable());
        handleJudgeGameState();
        dispatch(check_last_pitch_game_state());
        setDefaultState();
    }

    return (
        <Button size="large" onClick={handleClick} fullWidth variant="outlined" color="success">儲存</Button>
    );
}

export default StoreBtn;
