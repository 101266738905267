import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_滾飛平_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const GroundState = () => {

    let records = useAppSelector(state => state.baseballAppReducer.records);
    let groundState = records.滾飛平;

    let dispatch = useAppDispatch()
    let style = { margin: -3 }

    return (
        <FormControl component="fieldset">
            <RadioGroup value={groundState} onChange={(event) => { dispatch(set_滾飛平_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="滾" control={<Radio size="small" color="primary" />} label="滾" />
                <FormControlLabel style={style} value="飛" control={<Radio size="small" color="primary" />} label="飛" />
                <FormControlLabel style={style} value="平" control={<Radio size="small" color="primary" />} label="平" />
                <FormControlLabel style={style} value="P" control={<Radio size="small" color="primary" />} label="P" />
            </RadioGroup>
        </FormControl>
    );
}

export default GroundState;
