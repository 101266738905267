import { useAppDispatch } from '../app/hooks';
import useRecordFunc from '../hooks/useRecordFunc';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { set_checkBaseStateDialogOpen_state } from '../slices/baseballApp';

export default function RecordEventBtn() {

    const dispatch = useAppDispatch();

    const {
        handleAddStrikeState,
        handleSubStrikeState,
        handleAddBallState,
        handleSubBallState,
        handleAddOut,
        handleSubOut,
        handleAddBatterOrder,
        handleSubBatterOrder,
        handleSubScore,
        handleAddScore,
        handleAddInning,
        handleSubInning,
        hadleCleanSB,
        handleCleanOut
    } = useRecordFunc();

    const handleCheckBase = () => {
        dispatch(set_checkBaseStateDialogOpen_state(true))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Button onClick={handleAddScore} size="large" fullWidth variant="outlined">得分 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubScore} size="large" fullWidth variant="outlined">得分 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleAddBatterOrder} size="large" fullWidth variant="outlined">打序 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubBatterOrder} size="large" fullWidth variant="outlined">打序 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleAddStrikeState} size="large" fullWidth variant="outlined">好球 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubStrikeState} size="large" fullWidth variant="outlined">好球 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleAddBallState} size="large" fullWidth variant="outlined">壞球 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubBallState} size="large" fullWidth variant="outlined">壞球 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleAddOut} size="large" fullWidth variant="outlined">出局 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubOut} size="large" fullWidth variant="outlined">出局 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleAddInning} size="large" fullWidth variant="outlined">局數 +1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleSubInning} size="large" fullWidth variant="outlined">局數 -1</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={hadleCleanSB} size="large" fullWidth variant="outlined" color="secondary">清現球數</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleCleanOut} size="large" fullWidth variant="outlined" color="secondary">清出局數</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={handleCheckBase} size="large" fullWidth variant="outlined" color="secondary">壘上確認</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button size="large" fullWidth variant="outlined" color="secondary">代 跑/打</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button size="large" fullWidth variant="outlined" color="secondary">比賽暫停</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button size="large" fullWidth variant="outlined" color="secondary">更換守備</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button size="large" fullWidth variant="outlined" color="warning">回上一筆</Button>
                </Grid>
                <Grid item xs={3}>
                    <Button size="large" fullWidth variant="outlined" color="warning">比賽結束</Button>
                </Grid>
            </Grid>

        </>
    );
}
