import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const BaseEvent = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let baseEvent = records.結果;
    let style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={baseEvent} onChange={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="夾殺進壘" control={<Radio size="small" color="primary" />} label="夾殺進壘" />
                <FormControlLabel style={style} value="妨礙跑壘" control={<Radio size="small" color="primary" />} label="妨礙跑壘" />
                <FormControlLabel style={style} value="夾殺回壘" control={<Radio size="small" color="primary" />} label="夾殺回壘" />
                <FormControlLabel style={style} value="牽制失誤" control={<Radio size="small" color="primary" />} label="牽制失誤" />
                <FormControlLabel style={style} value="守備失誤" control={<Radio size="small" color="primary" />} label="守備失誤" />
                <FormControlLabel style={style} value="盜二成功" control={<Radio size="small" color="primary" />} label="盜二成功" />
                <FormControlLabel style={style} value="盜三成功" control={<Radio size="small" color="primary" />} label="盜三成功" />
                <FormControlLabel style={style} value="盜本成功" control={<Radio size="small" color="primary" />} label="盜本成功" />
                <FormControlLabel style={style} value="雙盜壘成" control={<Radio size="small" color="primary" />} label="雙盜壘成" />
                <FormControlLabel style={style} value="牽制" control={<Radio size="small" color="primary" />} label="牽制" />
            </RadioGroup>
        </FormControl>
    );
}

export default BaseEvent;
