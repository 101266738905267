import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_好壞球_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const SwingState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const swingState = records.好壞球;
    const style = { margin: -3 };

    const handleEvent = (event) => {
        dispatch(set_好壞球_state(event.target.value))
    }

    return (
        <FormControl component="fieldset">
            <RadioGroup value={swingState} onChange={handleEvent} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="好球" control={<Radio size="small" color="primary" />} label="好球" />
                <FormControlLabel style={style} value="壞球" control={<Radio size="small" color="primary" />} label="壞球" />
            </RadioGroup>
        </FormControl>
    );
}

export default SwingState;
