import { useAppSelector } from '../app/hooks';
import useSetLineUp from '../hooks/useSetLineUp';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const AllBasesAddBtn = () => {
    const configs = useAppSelector((state) => state.baseballAppReducer.config);

    const { loadingState, setLoadingState, handleSetLineup } = useSetLineUp();

    const handleClick = () => {
        setLoadingState(true);
        handleSetLineup(configs.base_id);
    }

    return (
        <>
            <Grid spacing={0.5} container>
                <Grid item xs={6} sm={6} md={6}>
                    <Button size="medium" fullWidth variant="outlined" color="warning">跑壘全推進</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    {configs.base_id !== "" ? (
                        <LoadingButton
                            size="medium"
                            onClick={handleClick}
                            loading={loadingState}
                            loadingIndicator="重設中"
                            variant="outlined"
                            color="warning"
                            fullWidth
                        >
                            重設名單
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            size="medium"
                            disabled={true}
                            variant="outlined"
                            color="warning"
                            fullWidth
                        >
                            重設名單
                        </LoadingButton>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default AllBasesAddBtn;
