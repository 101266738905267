import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_自責分_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const EarnedRunState = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let earnedRunState = records.自責分;
    let style = { margin: -3 }

    return (
        <FormControl component="fieldset">
            <RadioGroup value={earnedRunState} onChange={(event) => { dispatch(set_自責分_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="自責分" control={<Radio size="small" color="primary" />} label="自責分" />
                <FormControlLabel style={style} value="非自責分" control={<Radio size="small" color="primary" />} label="非自責分" />
            </RadioGroup>
        </FormControl>
    );
}

export default EarnedRunState;
