import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
    set_ballState_state, set_guestBattingOrder_state, set_homeBattingOrder_state,
    set_一出局_state, set_二出局_state, set_三出局_state, set_出局_state,
    set_outState_state, set_strikeState_state, set_客隊得分_state, set_主隊得分_state,
    set_attackTeamName_state, set_defenseTeamName_state, set_inningType_state, set_好球數_state, set_壞球數_state,
    set_inning_state, set_打者_state, set_guestInPlayBatterName_state, set_homeInPlayBatterName_state,
    set_changeInningMsgBoxPop_state, set_subInningDialogOpen_state, set_結果_state
} from '../slices/baseballApp';

export default function useRecordFunc() {

    const dispatch = useAppDispatch()
    const records = useAppSelector(state => state.baseballAppReducer.records);
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    function handleAddStrikeState() {
        switch (configs.strikeState) {
            case 2: // 儲存觸發
                if (records.事件 === '界外') {
                    dispatch(set_strikeState_state(2))
                } else {
                    dispatch(set_strikeState_state(configs.strikeState + 1))
                    dispatch(set_好球數_state(parseInt(records.好球數) + 1))
                    if (records.結果 === "") {
                        dispatch(set_結果_state('三振'))
                        if (configs.attackTeamName === configs.guestTeamName) {
                            outRunner(configs.guestInPlayBatterName)
                        } else {
                            outRunner(configs.homeInPlayBatterName)
                        }
                    }
                }
                break;
            case 3: // 手動觸發
                // 預防user加超過3顆好球
                dispatch(set_strikeState_state(3))
                break;
            default:
                dispatch(set_strikeState_state(configs.strikeState + 1))
                dispatch(set_好球數_state(parseInt(records.好球數) + 1))
                break;
        }
    }

    function outRunner(runnerName: string) {
        switch (configs.outState) {
            case 0:
                dispatch(set_一出局_state(runnerName))
                dispatch(set_出局_state(1))
                break;
            case 1:
                dispatch(set_二出局_state(runnerName))
                dispatch(set_出局_state(1))
                break;
            case 2:
                dispatch(set_三出局_state(runnerName))
                dispatch(set_出局_state(1))
                break;

            default:
                break;
        }
    }

    function handleSubStrikeState() {
        switch (configs.strikeState) {
            case 0:
                dispatch(set_strikeState_state(0))
                break;
            default:
                dispatch(set_strikeState_state(configs.strikeState - 1))
                break;
        }
    }

    function handleAddBallState() {
        switch (configs.ballState) {
            case 3: // 儲存觸發
                dispatch(set_ballState_state(4))
                dispatch(set_結果_state('四壞'))
                break;
            case 4: // 手動觸發
                // 預防user加超過4顆壞球
                dispatch(set_ballState_state(4))
                break;
            default:
                dispatch(set_ballState_state(configs.ballState + 1))
                dispatch(set_壞球數_state(parseInt(records.壞球數) + 1))
                break;
        }
    }

    function handleSubBallState() {
        switch (configs.ballState) {
            case 0:
                dispatch(set_ballState_state(0))
                break;
            default:
                dispatch(set_ballState_state(configs.ballState - 1))
                break;
        }
    }

    function handleAddOut() {
        switch (configs.outState) {
            case 3:
                dispatch(set_outState_state(3))
                break;
            default:
                if (records.結果 === '雙殺') {
                    dispatch(set_outState_state(configs.outState + 2))
                } else {
                    dispatch(set_outState_state(configs.outState + 1))
                }
                break;
        }
    }

    function handleSubOut() {
        switch (configs.outState) {
            case 0:
                dispatch(set_outState_state(0))
                break;
            default:
                dispatch(set_outState_state(configs.outState - 1))
                break;
        }
    }

    function handleBatterName(order: number) {
        if (configs.attackTeamName === configs.guestTeamName) {
            for (let index = 0; index < configs.guestRoster.length; index++) {
                if (configs.guestRoster[index].打序 === String(order)) {
                    dispatch(set_打者_state(configs.guestRoster[index].姓名))
                    dispatch(set_guestInPlayBatterName_state(configs.guestRoster[index].姓名))
                }
            }
        } else {
            for (let index = 0; index < configs.homeRoster.length; index++) {
                if (configs.homeRoster[index].打序 === String(order)) {
                    dispatch(set_打者_state(configs.homeRoster[index].姓名))
                    dispatch(set_homeInPlayBatterName_state(configs.homeRoster[index].姓名))
                }
            }
        }
    }

    function handleAddBatterOrder() {
        if (configs.attackTeamName === configs.guestTeamName) {
            if (configs.guestBattingOrder === 9) {
                dispatch(set_guestBattingOrder_state(1))
                handleBatterName(1)
            } else {
                dispatch(set_guestBattingOrder_state(configs.guestBattingOrder + 1))
                handleBatterName(configs.guestBattingOrder + 1)
            }
        } else {
            if (configs.homeBattingOrder === 9) {
                dispatch(set_homeBattingOrder_state(1))
                handleBatterName(1)
            } else {
                dispatch(set_homeBattingOrder_state(configs.homeBattingOrder + 1))
                handleBatterName(configs.homeBattingOrder + 1)
            }
        }
    }

    function handleSubBatterOrder() {
        if (configs.attackTeamName === configs.guestTeamName) {
            if (configs.guestBattingOrder === 1) {
                dispatch(set_guestBattingOrder_state(9))
                handleBatterName(9)
            } else {
                dispatch(set_guestBattingOrder_state(configs.guestBattingOrder - 1))
                handleBatterName(configs.guestBattingOrder - 1)
            }
        } else {
            if (configs.homeBattingOrder === 1) {
                dispatch(set_homeBattingOrder_state(9))
                handleBatterName(9)
            } else {
                dispatch(set_homeBattingOrder_state(configs.homeBattingOrder - 1))
                handleBatterName(configs.homeBattingOrder - 1)
            }
        }
    }

    function handleAddScore() {
        if (configs.attackTeamName === configs.guestTeamName) {
            dispatch(set_客隊得分_state(parseInt(records.客隊得分) + 1))
        } else {
            dispatch(set_主隊得分_state(parseInt(records.主隊得分) + 1))
        }
    }

    function handleSubScore() {
        if (configs.attackTeamName === configs.guestTeamName) {
            dispatch(set_客隊得分_state(parseInt(records.客隊得分) - 1))
        } else {
            dispatch(set_主隊得分_state(parseInt(records.主隊得分) - 1))
        }
    }

    function handleAddInning() {
        dispatch(set_changeInningMsgBoxPop_state(true))
        // if (configs.attackTeamName === configs.guestTeamName) {
        //     dispatch(set_strikeState_state(0))
        //     dispatch(set_ballState_state(0))
        //     dispatch(set_outState_state(0))
        //     dispatch(set_attackTeamName_state(configs.homeTeamName))
        //     dispatch(set_defenseTeamName_state(configs.guestTeamName))
        //     dispatch(set_inningType_state("Bottom"))
        // } else {
        //     dispatch(set_strikeState_state(0))
        //     dispatch(set_ballState_state(0))
        //     dispatch(set_outState_state(0))
        //     dispatch(set_attackTeamName_state(configs.guestTeamName))
        //     dispatch(set_defenseTeamName_state(configs.homeTeamName))
        //     dispatch(set_inningType_state("Top"))
        // }
        // if (configs.inningType === "Bottom") {
        //     dispatch(set_inning_state(configs.inning + 1))
        // }
    }

    function handleSubInning() {
        dispatch(set_subInningDialogOpen_state(true))
        // if (configs.attackTeamName === configs.guestTeamName) {
        //     dispatch(set_strikeState_state(0))
        //     dispatch(set_ballState_state(0))
        //     dispatch(set_outState_state(0))
        //     dispatch(set_attackTeamName_state(configs.homeTeamName))
        //     dispatch(set_defenseTeamName_state(configs.guestTeamName))
        //     dispatch(set_inningType_state("Bottom"))
        //     dispatch(set_inning_state(configs.inning - 1))
        // } else {
        //     dispatch(set_strikeState_state(0))
        //     dispatch(set_ballState_state(0))
        //     dispatch(set_outState_state(0))
        //     dispatch(set_attackTeamName_state(configs.guestTeamName))
        //     dispatch(set_defenseTeamName_state(configs.homeTeamName))
        //     dispatch(set_inningType_state("Top"))
        // }
    }

    function hadleCleanSB() {
        dispatch(set_strikeState_state(0))
        dispatch(set_ballState_state(0))
    }

    function handleCleanOut() {
        handleAddInning()
        hadleCleanSB()
        dispatch(set_outState_state(0))
    }

    return {
        handleAddStrikeState,
        handleSubStrikeState,
        handleAddBallState,
        handleSubBallState,
        handleAddOut,
        handleSubOut,
        handleAddBatterOrder,
        handleSubBatterOrder,
        handleSubScore,
        handleAddScore,
        handleAddInning,
        handleSubInning,
        hadleCleanSB,
        handleCleanOut
    };

}
