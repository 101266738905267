import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import Box from '@mui/material/Box';
import Select from "react-select";

const ChangeBatter = () => {

    return (
        <>
            <Box>
                <Select placeholder="更換當前打者" />
            </Box>
        </>
    );
}

export default ChangeBatter;
