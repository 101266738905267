import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const HitState = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let hitState = records.結果;
    let style = { margin: -4 }

    return (
        <FormControl component="fieldset">
            <RadioGroup value={hitState} onChange={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="一壘安" control={<Radio size="small" color="primary" />} label="一壘安" />
                <FormControlLabel style={style} value="二壘安" control={<Radio size="small" color="primary" />} label="二壘安" />
                <FormControlLabel style={style} value="三壘安" control={<Radio size="small" color="primary" />} label="三壘安" />
                <FormControlLabel style={style} value="全壘打" control={<Radio size="small" color="primary" />} label="全壘打" />
            </RadioGroup>
        </FormControl>
    );
}

export default HitState;
