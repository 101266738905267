import { useAppDispatch } from '../app/hooks';
import { set_playtime_state } from '../slices/baseballApp';

import Button from '@mui/material/Button';

const MarkOut = () => {

    let dispatch = useAppDispatch()
    let playtimeHandleClick = () => {
        const current = new Date();
        const time = `${current.getTime()}`;
        dispatch(set_playtime_state(time))
    }

    return (
        <Button onClick={playtimeHandleClick} size="large" fullWidth variant="outlined" color="inherit">mark out</Button>
    );
}

export default MarkOut;
