import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_揮棒_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const BattingState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const battingState = records.揮棒;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={battingState} onChange={(event) => { dispatch(set_揮棒_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="出棒" control={<Radio size="small" color="primary" />} label="出棒" />
                <FormControlLabel style={style} value="觸擊" control={<Radio size="small" color="primary" />} label="觸擊" />
                <FormControlLabel style={style} value="未出棒" control={<Radio size="small" color="primary" />} label="未出棒" />
            </RadioGroup>
        </FormControl>
    );
}

export default BattingState;
